.layout-topbar-conpaasspain {
  --topbar-bg-color: var(--primary-color-topbar);
  --topbar-text-color: var(--color-white-1);
}

.layout-topbar-conpaasspain .layout-topbar {
  color: var(--color-white-1);
  background-color: var(--primary-color-topbar);
  background-image: URL(../content/ffa4a8dbb9c3e3f85b901cf725e4b0b2.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 238px center;
}

.layout-topbar-conpaasspain .layout-topbar a {
  color: var(--color-white-1);
}

.layout-topbar-conpaasspain .layout-topbar a:hover {
  background-color: rgba(255, 255, 255, 0.12);
  transition: background-color 0.2s;
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-left {
  background-color: var(--primary-color-menu);
  background-image: URL(../content/ffa4a8dbb9c3e3f85b901cf725e4b0b2.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 240px center;
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-left .layout-menu-button {
  color: white;
  background-color: var(--color-black-3);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-left .layout-menu-button:hover {
  background-color: var(--menu-button-flow);
  transition: background-color 0.2s;
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-left .layout-megamenu {
  background: none;
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-left .layout-megamenu.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text,
.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-left .layout-megamenu.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon,
.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-left .layout-megamenu.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
  color: var(--color-white-1);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-left .layout-megamenu.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.12);
  transition: background-color 0.2s;
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-action-panel {
  background-color: var(--color-white-1);
  color: var(--color-black-3);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-action-panel a {
  color: var(--color-black-3);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-action-panel a:hover {
  background-color: transparent;
  transition: none;
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-action-panel .layout-topbar-action-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: background-color 0.2s;
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel input {
  background-color: var(--color-white-5);
  color: var(--color-black-3);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel input:hover {
  background-color: var(--color-white-5);
  color: var(--color-black-3);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel .p-inputgroup-addon:first-child,
.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel .p-inputgroup button:first-child,
.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel .p-inputgroup input:first-child {
  background-color: var(--color-white-5);
  color: var(--color-black-3);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel .p-inputgroup-addon:last-child,
.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel .p-inputgroup button:last-child,
.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel .p-inputgroup input:last-child {
  background-color: var(--color-white-5);
  color: var(--color-black-3);
}

.layout-topbar-conpaasspain .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item.layout-search-item .layout-search-panel .p-inputgroup-addon .p-button-text {
  color: var(--color-black-3);
}